<template>
  <div>
    <div class="ml-auto d-flex" v-if="!isDesktop">
      <template v-if="!isChange && contents.isEditable">
        <div class="mr-3">
          <a-button icon="edit" @click.prevent="showEdit">Edit</a-button>
        </div>
        <div>
          <a-button icon="delete" type="danger">Delete</a-button>
        </div>
      </template>
    </div>
    <template v-if="!isChange">
      <div class="d-flex mb-3 align-items-center mt-4">
        <div class="mr-4 w-100" style="max-width: 600px;">
          <div class="title-detail">{{contents.judul || '-'}}</div>
        </div>
        <div class="ml-auto d-flex" v-if="isDesktop">
          <template v-if="contents.isEditable">
            <div class="mr-3">
              <a-button icon="edit" @click.prevent="showEdit">Edit</a-button>
            </div>
            <div>
              <a-button icon="delete" type="danger" :loading="loadingDeleteAnnouncement" @click.prevent="handleDeleteAnnouncement()">Delete</a-button>
            </div>
          </template>
        </div>
      </div>
      <div class="meta">
        <a-row type="flex" align="middle">
          <a-col :md="3" :sm="24"><p class="meta-title">Created By</p></a-col>
          <a-col :md="5" :sm="24"><p class="meta-content">{{contents.guru ? contents.guru.nama : 'Admin'}}</p></a-col>
        </a-row>
        <a-row class="mt-2" type="flex" align="middle">
          <a-col :md="3" :sm="24"><p class="meta-title">Created At</p></a-col>
          <a-col :md="5" :sm="24"><p class="meta-content">{{contents.firstCreated || '-'}}</p></a-col>
        </a-row>
        <a-row class="mt-2" type="flex" align="middle">
          <a-col :md="3" :sm="24"><p class="meta-title">Last Modified</p></a-col>
          <a-col :md="5" :sm="24"><p class="meta-content">{{contents.lastModified || '-'}}</p></a-col>
        </a-row>
        <a-row class="mt-2" type="flex" align="middle">
          <a-col :md="3" :sm="24"><p class="meta-title">Recipient</p></a-col>
          <a-col :md="5" :sm="24" v-if="contents.tag"><a-tag class="my-1" v-for="(tag, j) in contents.tag" :key="j" :color="tag.color">{{tag.title}}</a-tag></a-col>
        </a-row>
        <a-row v-if="role !== 'Student'" class="mt-2" type="flex" align="middle">
          <a-col :md="3" :sm="24"><p class="meta-title">Target</p></a-col>
          <a-col :md="5" :sm="24" v-if="contents.tag"><a-tag class="my-1" :color="contents.is_orangtua ? '#FAAD15' : '#5AA9A9'">{{contents.is_orangtua ? "Parent" : "Student"}}</a-tag></a-col>
        </a-row>
      </div>
      <div class="my-4">
        <div v-if="contents.gambar_utama_filename">
          <img style="width: 100%; max-height: 300px;" :src="`${config.apiUrl}/pengumuman/${contents.gambar_utama_filename}`">
        </div>
        <div v-html="contents.deskripsi">
        </div>
      </div>
      <div>
        <div v-for="file, i in contents.files" :key="i">
          <a download target="_blank" :href="`${config.apiUrl}/${file.filepath}/${file.filename}`" class="hsn-announcement-file-card d-flex justify-content-between align-items-center mt-3">
            <a-icon type="file-text" style="color: rgba(0, 0, 0, 0.65);" />
            <div style="width: 100%; margin-inline: 10px;">
              <p style="color: rgba(0, 0, 0, 0.65);">{{file.filename}}</p>
            </div>
            <a-icon type="download" style="color: rgba(0, 0, 0, 0.65);" />
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <a-row>
        <a-col :lg="12" :md="24">
          <a-form-item label="Title" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
          <a-input
            placeholder="Insert your title..."
            v-model="newData.judul"
            size="large"
          />
        </a-col>
      </a-row>
      <a-row class="my-2">
        <a-col :lg="12" :md="24">
          <a-form-item label="Target" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
          <a-radio-group button-style="solid" class="w-100" size="large" v-model="newData.target">
            <a-radio-button class="w-50 text-center" value="murid">Student</a-radio-button>
            <a-radio-button class="w-50 text-center" value="orangtua">Parent</a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :lg="4">
          <a-form-item label="Recipient" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
          <a-select
            v-model="newData.type"
            size="large"
            class="w-100"
            style="min-width: 150px;"
            @change="handleChange('type')"
          >
            <a-select-option value="general">General</a-select-option>
            <a-select-option v-if="levels.length" value="id_level">By Level</a-select-option>
            <a-select-option v-if="classes.length" value="id_kelas">By Class</a-select-option>
          </a-select>
        </a-col>
        <a-col :lg="6">
          <template v-if="newData.type === 'id_level'">
            <a-form-item label="Level" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
            <a-select
              mode="multiple"
              v-model="newData.id_level"
              size="large"
              class="w-100"
              style="min-width: 150px;"
            >
              <a-select-option v-for="level in levels" :key="level.id" :value="level.id">{{level.nama}}</a-select-option>
            </a-select>
          </template>
          <template v-else-if="newData.type === 'id_kelas'">
            <a-form-item label="Classes" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
            <a-select
              mode="multiple"
              v-model="newData.id_kelas"
              size="large"
              class="w-100"
              style="min-width: 150px;"
            >
              <a-select-option v-for="kelas in classes" :key="kelas.id" :value="kelas.id">{{kelas.title}}</a-select-option>
            </a-select>
          </template>
        </a-col>
        <a-col :lg="24">
          <a-form-item label="Description" class="mb-0" :colon="false"></a-form-item>
          <ckeditor
            v-model="newData.deskripsi"
            :editor="editor"
            :config="editorConfig"
          ></ckeditor>
        </a-col>
        <a-col :lg="24" :md="24">
          <a-form-model-item class="mb-0" :colon="false">
            <label for="label">Attachments <small class="font-italic">(file must be pdf, max. 3 files)</small></label>
          </a-form-model-item>
          <a-upload
            accept="application/pdf"
            :customRequest="() => {}"
            :fileList="[]"
            name="lampiran"
            :beforeUpload="file => uploadFile(file)"
          >
            <a-button :disabled="newData.attachments.length == 3" size="large" icon="upload">Upload Documents</a-button>
          </a-upload>
          <div>
            <div v-for="(file, i) in newData.files" :key="'file-old' + i" class="d-flex justify-content-between align-items-center mt-3">
              <a-icon type="file-text" style="color: black;" />
              <div style="width: 100%; margin-inline: 10px;">
                <p>{{file.filename}}</p>
              </div>
              <a-button :block="isMobile" @click.prevent="handleRemove(file, true)" size="large" icon="delete" />
            </div>
            <div v-for="(file, i) in newData.attachments" :key="i" class="d-flex justify-content-between align-items-center mt-3">
              <a-icon type="file-text" style="color: black;" />
              <div style="width: 100%; margin-inline: 10px;">
                <p>{{file.name}}</p>
              </div>
              <a-button @click.prevent="handleRemove(file)" size="large" icon="delete" />
            </div>
          </div>
          <!-- <div>
            <p v-for="(file, i) in newData.attachments" :key="i">Tes File</p>
          </div> -->
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import config from '@/config'
// function getBase64(img, callback) {
//   const reader = new FileReader()
//   reader.addEventListener('load', () => callback(reader.result))
//   reader.readAsDataURL(img)
// }

export default {
  data() {
    return {
      loadingDeleteAnnouncement: false,
      config,
    }
  },
  props: {
    newData: {
      type: Object,
    },
    classes: {
      type: Array,
    },
    levels: {
      type: Array,
    },
    contents: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
    isCreate: {
      type: Boolean,
    },
  },
  methods: {
    handleChange(col) {
      this.$emit('handle-change', col)
    },
    showEdit() {
      this.$emit('showEdit')
    },
    handleDeleteAnnouncement() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this announcement?</div>
        ),
        onOk: async () => {
          try {
            this.loadingDeleteAnnouncement = true
            await this.$store.dispatch('announcement/DELETE_ANNOUNCEMENT', {
              idAnnouncement: this.contents.id,
            })
            this.$emit('afterDelete')
            this.$notification.success({
              message: 'Success',
              description:
                'Announcement has been deleted',
            })
            this.loadingDeleteAnnouncement = false
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: 'Announcement is not deleted',
            })
          }
        },
        onCancel: () => {
          this.loadingDeleteAnnouncement = false
          this.visibleDetail = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async uploadFile(file, type) {
      try {
        const isPdf = file.type === 'application/pdf'
        if (!isPdf) {
          this.$notification.error({
            message: 'Upload Failed',
            description: 'Only accept PDF file.',
          })
        } else {
          this.$emit('addAttachment', file)
        }
      } catch (err) {
        console.log(err)
        this.$notification.error({
          message: 'GAGAL MENGUNGGAH',
          description: 'Terjadi error dalam mengunggah, silahkan coba lagi.',
        })
        return false
      }
    },
    handleRemove(file, isOld) {
      // console.log(file)
      this.$emit('removeAttachment', file, isOld)
    },
  },
  computed: {
    isChange() {
      return this.isEdit || this.isCreate
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      const config = this.$store.state.ckEditor.editorConfig
      const items = config.toolbar.items.filter(item => item !== 'MathType')
      return {
        ...config,
        toolbar: {
          items: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            ...items,
          ],
          shouldNotGroupWhenFull: true,
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';

.modal-card-announcement {
  .title-detail {
    font-size: 20px;
    font-weight: 600;
    color: #303030
  }

  .hsn-announcement-file-card {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.65);
    padding-inline: 15px;
    padding-block: 7px;
    cursor: pointer;
    &:hover {
      @include transition-middle;
      border: 1px solid $primary;
      // transform: scale(1.005);
      p, i {
        color: $primary!important;
      }
    }
  }

  .meta {
      .meta-title {
        font-size: 13px!important;
        color: #9c9c9c;
        letter-spacing: 0.5px;
      }

      .meta-content {
        font-size: 13px!important;
        color: #2e2e2e;
      }
    }

    .ck-editor__editable {
      min-height: 300px;
    }
}
</style>
